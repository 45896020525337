<template>
  <div id="home">
    <Banner :destinations="destinations"></Banner>
    <Searchbtn
      @show="showBanner = !showBanner"
      @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <Propositions></Propositions>
    <AccommodationCard
        :user="this.user"
        @connection="openModal"
        :lodgings="accommodations"
        :wishlist="wishlist"
        v-if="accommodations"
    ></AccommodationCard>
    <lodgingCard
      :user="this.user"
      @connection="openModal"
      :lodgings="lodgings"
      :wishlist="wishlist"
      v-if="lodgings"
    ></lodgingCard>
<!--    <Exclusive :exclusives="exclusives" v-if="exclusives"></Exclusive>-->
<!--    <Weekend :weekends="weekends" v-if="weekends"></Weekend>    -->
<!--    <ExperienceHome-->
<!--      :experiencesByCategory="experiences"-->
<!--      v-if="experiences"-->
<!--    ></ExperienceHome>-->
    <GiftCardHome></GiftCardHome>
    <Destination :destinations="destinations" v-if="destinations"></Destination>
    <CollectionHome
      :collections="collections"
      v-if="collections"
    ></CollectionHome>
    <Tips></Tips>
    <EvergreenHome
      :evergreens="this.evergreens"
      v-if="evergreens"
    ></EvergreenHome>
    <LabelHome></LabelHome>
    <WhoIsHome></WhoIsHome>
    <FidHome :user="this.user" @connection="openModal"></FidHome>
    <ScrollUp></ScrollUp>
  </div>
</template>

<script>
import fetcher from "../packs/shared/api.js";
import Banner from "../components/shared/Banner.vue";
import Tips from "../components/Tips.vue";
import Propositions from "../components/Propositions.vue";
import Weekend from "../components/Weekend.vue";
import Exclusive from "../components/Exclusive.vue";
import ExperienceHome from "../components/ExperienceHome.vue";
import GiftCardHome from "../components/GiftCardHome.vue";
import Destination from "../components/Destination.vue";
import CollectionHome from "../components/collection/CollectionHome.vue";
import EvergreenHome from "../components/EvergreenHome.vue";
import LabelHome from "../components/LabelHome.vue";
import WhoIsHome from "../components/WhoIsHome.vue";
import FidHome from "../components/FidHome.vue";
import lodgingCard from "../components/cards/LodgingCard.vue";
import ScrollUp from "../components/btns/ScrollUp.vue";
import Reassurance from "../components/Reassurance.vue";
import Searchbtn from "../components/btns/Searchbtn.vue";
import Searchbanner from "../components/btns/Searchbanner.vue";
import AccommodationCard from "../components/cards/AccommodationCard.vue";

export default {
  name: "home",
  props: ["user"],
  components: {
    Banner,
    Tips,
    Propositions,
    lodgingCard,
    Weekend,
    Exclusive,
    ExperienceHome,
    GiftCardHome,
    Destination,
    CollectionHome,
    EvergreenHome,
    LabelHome,
    WhoIsHome,
    FidHome,
    ScrollUp,
    Searchbtn,
    Reassurance,
    Searchbanner,
    AccommodationCard,
  },
  data() {
    return {
      showBanner: false,
      lodgings: null,
      weekends: null,
      exclusives: null,
      experiences: null,
      destinations: null,
      collections: null,
      evergreens: null,
      wishlist: null,
      accommodations: null,
    };
  },
  methods: {
    openModal() {
      this.$root.$emit("openModal");
    },
    redirectToTop() {
      //window.location.href = "/reservation-hotel?search="
      window.scrollTo(0, "#navbar");
    },
    async fetchdatas() {
      try {
        const responseLodging = await fetcher.get(
          "/reservation-hotel?page=1&lodgings_count=3"
        );
        this.lodgings = responseLodging.data;
        const responseAccommodation     = await fetcher.get(
            "/reservation-hotel?home=true"
        );
        this.accommodations = responseAccommodation.data;

        const responseWeekend = await fetcher.get("/weekends/");
        this.weekends = await responseWeekend.data;
        const responseExclusive = await fetcher.get("/offres-exclusives/");
        this.exclusives = await responseExclusive.data;
        const responseExperience = await fetcher.get("/basic/experiences/");
        this.experiences = responseExperience.data;
        const responseDestination = await fetcher.get("/destination");
        this.destinations = await responseDestination.data;
        const responseCollection = await fetcher.get("/collections/");
        this.collections = responseCollection.data;
        const responseEvergreen = await fetcher.get(
          "/programme-evergreen-demarche-environnementale/"
        );
        this.evergreens = await responseEvergreen.data;
        if (this.user) {
          const responseWishlist = await fetcher.get("/wishlists");
          this.wishlist = responseWishlist.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.fetchdatas();
  },
};
</script>
