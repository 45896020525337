import { render, staticRenderFns } from "./Propositions.vue?vue&type=template&id=32ab5340&scoped=true&"
import script from "./Propositions.vue?vue&type=script&lang=js&"
export * from "./Propositions.vue?vue&type=script&lang=js&"
import style1 from "./Propositions.vue?vue&type=style&index=1&id=32ab5340&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ab5340",
  null
  
)

export default component.exports